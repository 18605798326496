<div id="sf-calendar-mgr-table-comp"
  fxLayout="column">
  <h3 class="mat-h3">{{calendarTitle}}</h3>

  <div fxLayout="row"
    fxLayoutGap="16px">
    <button mat-flat-button
      class="calendar-add-btn"
      (click)="addCalMgr()"
      [attr.data-cy]="'action-to-add-user-' + calendarTitle"
      color="primary">Add</button>

    <button mat-stroked-button
      *ngIf="additionalDetail.length > 0"
      (click)="okayDialogService.okay(this.calendarTitle, this.additionalDetail)"
      [attr.data-cy]="'about-this-section-' + calendarTitle"
      color="primary">More Info</button>
  </div>
  <div [ngClass]="{'hidden': formGroup.get('managers')?.value?.length > 0}">
    <p [attr.data-cy]="calendarTitle + '-empty-section'"
      class="empty-cal-mgrs">-- No Calendar Managers Added Yet --</p>
  </div>

  <table class="display-flex"
    [ngClass]="{'hidden': formGroup.get('managers')?.value?.length < 1}"
    mat-table
    class="mat-elevation-z0 starfish-data-table"
    [dataSource]="dataSource">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell
        *matHeaderCellDef> User Name </th>
      <td mat-cell
        [attr.data-cy]="element.displayName + '-added user-' + calendarTitle"
        class="empty-cal-mgrs"
        *matCellDef="let element"> {{element.displayName | searchServiceGetNamePipe | async}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="userid">
      <th mat-header-cell
        *matHeaderCellDef> User ID </th>
      <td mat-cell
        *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- remove Column -->
    <ng-container matColumnDef="delete">
      <th mat-header-cell
        *matHeaderCellDef> <span class="center-align-flex">Remove This User</span> </th>
      <td mat-cell
        *matCellDef="let element; let i = index"
        [attr.data-cy]="element.userId+'-remove-user'">
        <span class="center-align-flex">
          <button mat-stroked-button
            matTooltip="Delete this configuration for this delegation"
            mat-button
            (click)='removeCalendarMgr(i)'
            color="warn"
            [attr.data-cy]="element.userId+'-remove-user-btn'">
            Delete
          </button> </span>
      </td>
    </ng-container>

    <tr mat-header-row
      *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
      *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
