<span [formGroup]="formGroup"
  class="sf-class-config mat-typography">

  <mat-form-field appearance="outline"
    class="form-field">
    <mat-label>Subject</mat-label>
    <mat-select [attr.data-cy]="'select-subject-' + classIndex"
      formControlName="subject">
      <mat-option [attr.data-cy]="'no-class-needed-option' + classIndex"
        value="ALL">-- All Subjects --</mat-option>
      <mat-option *ngFor="let item of possibleSubjects"
        [attr.data-cy]="'subject-' + item.id + '-option'"
        [value]="item.id">{{item.displayName}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline"
    class="form-field">
    <mat-label>Course</mat-label>
    <mat-select [attr.data-cy]="'select-course' + classIndex"
      formControlName="course">
      <mat-option [attr.data-cy]="'no-course-needed-option' + classIndex"
        value="ALL">-- All Courses --</mat-option>
      <mat-option *ngFor="let item of (possibleCourses | sortCourseNumber)"
        [attr.data-cy]="'course-' + item.id + '-option' + classIndex"
        [value]="item.id">{{item.displayName}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline"
    class="form-field">
    <mat-label>Section #</mat-label>
    <mat-select [attr.data-cy]="'select-section' + classIndex"
      formControlName="section">
      <mat-option [attr.data-cy]="'no-section-needed-option' + classIndex"
        value="ALL">-- All Sections --</mat-option>
      <mat-option *ngFor="let item of (possibleSections | sortCourseNumber)"
        [attr.data-cy]="'section-' + item.id + '-option' + classIndex"
        [value]="item.id">{{item.displayName}}</mat-option>
    </mat-select>
  </mat-form-field>
</span>
