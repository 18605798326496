<div class="compare-users-comp">

  <span *ngIf="usersAdded?.length > 0 && usersRemoved?.length > 0">
    You are about to: <a (click)="addUserEmitter.emit(usersAdded)">add {{usersAdded?.length}} new student(s)</a> and <a (click)="deleteUserEmitter.emit(usersRemoved)">delete {{usersRemoved?.length}} old student(s).</a>
  </span>

  <span *ngIf="usersAdded?.length === 0 && usersRemoved?.length > 0">
    You are about to: <a (click)="deleteUserEmitter.emit(usersRemoved)">delete {{usersRemoved?.length}} old student(s).</a>
  </span>

  <span *ngIf="usersAdded?.length > 0 && usersRemoved?.length === 0">
    You are about to: <a (click)="addUserEmitter.emit(usersAdded)">add {{usersAdded?.length}} new student(s).</a>
  </span>

</div>
