<div fxFlex
  fxLayoutAlign="start center"
  class="sample-icon">
  <div class="big-circle-icon"
    [ngClass]="colorSelection | async"
    fxLayoutAlign="center center">
    <mat-icon class="role-icon"
      [svgIcon]="iconSelection | async"></mat-icon>
  </div>
</div>
