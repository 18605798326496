<button mat-flat-button
  class="sticky"
  data-cy="delegate-save-button"
  [disabled]="(facade.isUpdating$ | async)"
  (click)="saveUpdates(delegation.id)"
  [ngClass]="{'sticky-all-saved': delegationFormGroup.pristine}"
  color="accent">
  <mat-icon>save</mat-icon>
  <span>Save</span>
</button>

<div [formGroup]="delegationFormGroup"
  class="delegation-edit-form">
  <sf-delegation-form formControlName="delegation"
    (deleteRole)="markRoleAsDeleted($event)"
    [kiosksAvailable]="facade.kioskList$ | async"
    [roles]="roles">
  </sf-delegation-form>

  <mat-progress-bar *ngIf="facade.isUpdating$ | async"
    data-cy="save-role-progress-bar"
    mode="indeterminate"></mat-progress-bar>
</div>
