import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  ControlValueAccessor,
  FormGroupDirective,
  NgForm,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { OrgModel } from '@starfish-access/models';
import { OrgSelectComponent, OrgSelectItem } from '@starfish-access/shared';
import { isEmpty } from 'ramda';

/**
 * Only used internally but must be exported to
 * use in unit tests
 */
export interface SelectorModel {
  serviceList: OrgModel[];
  serviceDelegation: boolean;
  kioskList: OrgModel[];
  kioskDelegation: boolean;
}

const orgSelectItemToOrgModel = (input: OrgSelectItem): OrgModel => ({
  code: input.id,
  name: input.orgName,
});

const orgModelToOrgSelectItem = (input: OrgModel): OrgSelectItem => ({
  displayName: `${input.name} [${input.code}]`,
  id: input.code,
  orgName: input.name,
});

/**
 * This tells the typeahead component's input when to be invalid so it can display the
 * error state correctly.  Create a new one of these for each typeahead, services and kiosks,
 * and links the validity of the internal FormGroup's control to the typeahead input.
 */
export class StateMatcher implements ErrorStateMatcher {
  constructor(private ctrl: AbstractControl | null) {}
  isErrorState(_a: UntypedFormControl | null, _b: FormGroupDirective | NgForm | null): boolean {
    return !!(this.ctrl && this.ctrl.errors);
  }
}

@Component({
  selector: 'sf-service-selector',
  templateUrl: './service-selector.component.html',
  styleUrls: ['./service-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ServiceSelectorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ServiceSelectorComponent),
      multi: true,
    },
  ],
})
export class ServiceSelectorComponent implements ControlValueAccessor, Validator, AfterViewInit {
  @Input() possibleServices: {
    id: string;
    code: string;
    name: string;
  }[];
  @Input() possibleKiosks: {
    id: string;
    code: string;
    name: string;
  }[];
  @Input() formControl: UntypedFormControl;

  @ViewChild('serviceSelector')
  private serviceSelector: OrgSelectComponent;

  @ViewChild('kioskSelector', { static: true })
  private kioskSelector: OrgSelectComponent;

  servicesMatcher: StateMatcher;
  kiosksMatcher: StateMatcher;
  private formGroup: UntypedFormGroup;

  // Holds the service list value while the checkbox is unchecked so it can be restored upon re-check
  private tempServiceListValue: OrgSelectItem[] = [];
  // Holds the kiosk list value while the checkbox is unchecked so it can be restored upon re-check
  private tempKioskListValue: OrgSelectItem[] = [];

  private wasPatchedInternally = false;

  constructor(fb: UntypedFormBuilder) {
    const opts: AbstractControlOptions = {
      validators: ({ value }: any): ValidationErrors | null => {
        if (value && value.length === 0) {
          return { required: 'Select at least one thing' };
        } else {
          return null;
        }
      },
    };

    this.formGroup = fb.group({
      serviceCheckbox: new UntypedFormControl(false),
      serviceDelegation: new UntypedFormControl(false),
      serviceList: new UntypedFormControl([], opts),
      kioskCheckbox: new UntypedFormControl(false),
      kioskDelegation: new UntypedFormControl(false),
      kioskList: new UntypedFormControl([], opts),
    });

    this.servicesMatcher = new StateMatcher(this.serviceListControl);
    this.kiosksMatcher = new StateMatcher(this.kioskListControl);
  }

  get servicesCheckboxControl(): AbstractControl | null {
    return this.formGroup.get('serviceCheckbox');
  }

  get kiosksCheckboxControl(): AbstractControl | null {
    return this.formGroup.get('kioskCheckbox');
  }

  get kioskDelegationControl(): AbstractControl | null {
    return this.formGroup.get('kioskDelegation');
  }

  get serviceListControl(): AbstractControl | null {
    return this.formGroup.get('serviceList');
  }

  get serviceDelegationControl(): AbstractControl | null {
    return this.formGroup.get('serviceDelegation');
  }

  get kioskListControl(): AbstractControl | null {
    return this.formGroup.get('kioskList');
  }

  ngAfterViewInit(): void {
    if (this.serviceListControl) {
      this.serviceListControl.valueChanges.subscribe((x: OrgSelectItem[]) => {
        const changeValue = {
          ...this.formControl.value,
          serviceList: x ? x.map(orgSelectItemToOrgModel) : [],
        };
        this.propagateChange(changeValue);
      });
    }

    if (this.kioskListControl) {
      this.kioskListControl.valueChanges.subscribe((x: OrgSelectItem[]) => {
        const changeValue = {
          ...this.formControl.value,
          kioskList: x ? x.map(orgSelectItemToOrgModel) : [],
        };
        this.propagateChange(changeValue);
      });
    }

    if (this.serviceDelegationControl) {
      this.serviceDelegationControl.valueChanges.subscribe((x: boolean) => {
        const changeValue = {
          ...this.formControl.value,
          serviceDelegation: x,
        };
        this.propagateChange(changeValue);
      });
    }

    if (this.kioskDelegationControl) {
      this.kioskDelegationControl.valueChanges.subscribe((x: boolean) => {
        const changeValue = {
          ...this.formControl.value,
          kioskDelegation: x,
        };
        this.propagateChange(changeValue);
      });
    }

    if (this.servicesCheckboxControl) {
      this.servicesCheckboxControl.valueChanges.subscribe((x: boolean) => {
        if (!x) {
          this.servicesUnchecked();
        } else {
          this.servicesChecked();
        }
      });
    }
    if (this.kiosksCheckboxControl) {
      this.kiosksCheckboxControl.valueChanges.subscribe((x: boolean) => {
        if (!x) {
          this.kiosksUnchecked();
        } else {
          this.kiosksChecked();
        }
      });
    }
  }

  blurHandler(event: any): void {
    this.formControl.updateValueAndValidity();
    this.propagateTouch(event);
  }

  writeValue(obj: SelectorModel): void {
    if (obj) {
      this.tempServiceListValue = obj.serviceList ? obj.serviceList.map(orgModelToOrgSelectItem) : [];
      this.tempKioskListValue = obj.kioskList ? obj.kioskList.map(orgModelToOrgSelectItem) : [];
      const thisObject = {
        kioskList: this.tempKioskListValue,
        serviceList: this.tempServiceListValue,
        kioskCheckbox: obj.kioskList ? obj.kioskList.length > 0 : false,
        serviceCheckbox: obj.serviceList ? obj.serviceList.length > 0 : false,
        kioskDelegation: obj.kioskDelegation,
        serviceDelegation: obj.serviceDelegation,
      };
      this.formGroup.patchValue(thisObject);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  validate({ value }: AbstractControl): ValidationErrors | null {
    const validationErrors: ValidationErrors = {};
    if (this.servicesCheckboxControl && this.servicesCheckboxControl.value) {
      const serviceListValid = this.serviceListControl ? this.serviceListControl.valid : false;
      if (!serviceListValid) {
        validationErrors.servicesRequired = 'You must select at least one service';
      }
    }

    if (this.kiosksCheckboxControl && this.kiosksCheckboxControl.value) {
      const kioskListValid = this.kioskListControl ? this.kioskListControl.valid : false;
      if (!kioskListValid) {
        validationErrors.kiosksRequired = 'You must select at least one kiosk';
      }
    }

    return !isEmpty(validationErrors) ? validationErrors : null;
  }

  private servicesChecked(): void {
    if (this.serviceListControl) {
      if (this.tempServiceListValue.length > 0) {
        this.serviceListControl.patchValue(this.tempServiceListValue);
      }
    }
    if (!this.wasPatchedInternally) {
      this.serviceSelector.focus();
    } else {
      this.wasPatchedInternally = false;
    }
    if (this.serviceListControl) {
      this.serviceListControl.markAsTouched();
      this.serviceListControl.updateValueAndValidity();
    }
    this.formControl.updateValueAndValidity();
  }

  private servicesUnchecked(): void {
    if (this.serviceListControl) {
      if (this.serviceListControl.value && this.serviceListControl.value.length > 0) {
        this.tempServiceListValue = this.serviceListControl.value;
        this.serviceListControl.patchValue([]);
      }
      this.formControl.updateValueAndValidity();
      this.serviceListControl.updateValueAndValidity();
    }
  }

  private kiosksChecked(): void {
    // Restore the temp value, if any
    if (this.kioskListControl) {
      if (this.tempKioskListValue.length > 0) {
        // This gets called when you call this formControl's patchValue
        // because of the checkboxes changing state
        this.kioskListControl.patchValue(this.tempKioskListValue);
      }
    }

    // Focus the typeahead
    this.kioskSelector.focus();

    // Mark as Touched
    if (this.kioskListControl) {
      this.kioskListControl.markAsTouched();
    }

    // Make sure services are checked
    if (this.servicesCheckboxControl) {
      this.wasPatchedInternally = true;
      this.servicesCheckboxControl.patchValue(true, {});
    }
    this.formControl.updateValueAndValidity();
  }

  private kiosksUnchecked(): void {
    if (this.kioskListControl) {
      if (this.kioskListControl.value && this.kioskListControl.value.length > 0) {
        this.tempKioskListValue = this.kioskListControl.value;
        this.kioskListControl.patchValue([]);
      }
      this.formControl.updateValueAndValidity();
    }
  }

  private propagateChange: (_: any) => any = (_: any) => ({});
  private propagateTouch: (_: any) => any = (_: any) => ({});
}
