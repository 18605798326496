<span class="sf-delegation-create">
  <h1>New Delegation</h1>
  <form [formGroup]="delegationFormGroup"
    (keydown.enter)="$event.preventDefault()"
    #form
    (submit)="formSubmit($event)">
    <mat-card appearance="outlined">
      <mat-card-content>
        <sf-delegation-form formControlName="delegation"
          (deleteRole)="markRoleAsDeleted($event)"
          [kiosksAvailable]="facade.kioskList$ | async"
          [roles]="facade.delegatableRoles$ | async">
        </sf-delegation-form>
      </mat-card-content>
      <mat-card-actions align="end"
        class="actions-panel">
        <button mat-stroked-button
          class="button-right-margin"
          [disabled]="facade.isUpdating$ | async"
          type="button"
          routerLink="/delegations">Cancel</button>
        <button mat-flat-button
          data-cy="save-and-send-btn"
          [disabled]="facade.isUpdating$ | async"
          color="primary"
          type="submit">Save</button>
      </mat-card-actions>
      <mat-progress-bar *ngIf="facade.isUpdating$ | async"
        data-cy="save-role-progress-bar"
        mode="indeterminate"></mat-progress-bar>
    </mat-card>
  </form>
</span>
