<span class="sf-role-config-form mat-typography">
  <form [formGroup]="roleConfig">
    <h2 mat-dialog-title
      data-cy="edit-role-config-header">Edit {{roleConfig.value.displayName}} Config</h2>
    <div mat-dialog-content
      class="dialog-content">
      <sf-simple-service-selector [ngClass]="{'hidden': !orgsVisible}"
        [disabled]="data.config?.orgsFromRole?.selectionType === 'SPECIFIC_SERVICES'"
        [isOrg]=true
        class="full-width"
        formControlName="orgsFromDelegation"
        serviceType="Organization"
        [possibleItems]="possibleOrgs"
        (notificationEmitter)="registerFirstChange()">
      </sf-simple-service-selector>
      <sf-simple-service-selector [ngClass]="{'hidden': !kiosksVisible}"
        [disabled]="data.config?.kiosksFromRole?.selectionType === 'SPECIFIC_SERVICES'"
        class="full-width"
        formControlName="kiosksFromDelegation"
        serviceType="Kiosk"
        [possibleItems]="possibleKiosks"
        (notificationEmitter)="registerFirstChange()">
      </sf-simple-service-selector>

      <sf-simple-service-selector [ngClass]="{'hidden': !servicesVisible}"
        [disabled]="data.config?.servicesFromRole?.selectionType === 'SPECIFIC_SERVICES'"
        class="full-width"
        formControlName="servicesFromDelegation"
        serviceType="Service"
        [possibleItems]="possibleServices"
        (notificationEmitter)="registerFirstChange()">
      </sf-simple-service-selector>
      <sf-course-config *ngIf="coursesVisible"
        [courseSelectionEnabled]="data.roleInfo?.courseSelectionEnabled"
        formControlName="courseConfig">
      </sf-course-config>
    </div>
    <div mat-dialog-actions
      class="dialog-actions"
      align="end">
      <button mat-stroked-button
        data-cy="edit-role-config-cancel-button"
        [mat-dialog-close]="initialRoleConfig">Cancel</button>
      <button mat-flat-button
        data-cy="edit-role-config-ok-button"
        color="primary"
        [disabled]="!roleConfig.valid"
        [mat-dialog-close]="submit(roleConfig.getRawValue())">OK</button>
    </div>
  </form>
</span>
