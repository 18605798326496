import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatRadioButton,
  MatRadioChange,
} from '@angular/material/radio';
import { OkayDialogService } from '@starfish-access/core';
import { ClientRoleGroup, Role } from '@starfish-access/models';
import { equals } from 'ramda';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthFlowComponent } from '../auth-flow/auth-flow.component';
import { OrgsComponent } from '../orgs/orgs.component';
import { RoleDetailsFormFacade } from './role-details-form.facade';

@Component({
  selector: 'sf-role-details-form',
  templateUrl: './role-details-form.component.html',
  styleUrls: ['./role-details-form.component.scss'],
})
export class RoleDetailsFormComponent implements OnInit {
  @ViewChild('orgSelectionComponent', { static: true }) orgComp: OrgsComponent;
  @ViewChild('authFlowComponent', { static: true }) authComp: AuthFlowComponent;
  @ViewChild('existingGroupRadioButton') newGroupRadioBtn: MatRadioButton;

  @Input() roleToEdit: Role;
  @Input() containingRoleGroup: ClientRoleGroup;
  @Input() roleGroups$: ClientRoleGroup[];
  @Input() newRoleForm: UntypedFormGroup;
  @Input() includeNewGroup = true;

  groupSelectionSubj: Subject<boolean> = new BehaviorSubject<boolean>(true);
  groupSelection = 'existinggroup';

  constructor(public okayDialogService: OkayDialogService, public facade: RoleDetailsFormFacade) {}

  get servicesAndKiosksControl(): AbstractControl | null {
    return this.newRoleForm.get('servicesAndKiosks');
  }

  get availableToStudent(): AbstractControl | null {
    return this.newRoleForm.get('availableToStudent');
  }

  get availableToAdviser(): AbstractControl | null {
    return this.newRoleForm.get('availableToAdviser');
  }

  get availableInElevate(): AbstractControl | null {
    return this.newRoleForm.get('availableInElevate');
  }

  get descriptions(): AbstractControl | null {
    return this.newRoleForm.get('descriptions');
  }

  get relationships(): AbstractControl | null {
    return this.newRoleForm.get('relationships');
  }

  get orgMultiCtrl(): AbstractControl | null {
    return this.newRoleForm.get('multiOrg');
  }

  get orgListCtrl(): AbstractControl | null {
    return this.newRoleForm.get('orgList');
  }

  get kioskCtrl(): AbstractControl | null {
    return this.newRoleForm.get('kiosks');
  }

  get authCtrl(): AbstractControl | null {
    return this.newRoleForm.get('authFlow');
  }

  get servicesCtrl(): AbstractControl | null {
    return this.newRoleForm.get('services');
  }

  get adminOnlyCtrl(): AbstractControl | null {
    return this.newRoleForm.get('roleControl');
  }

  ngOnInit(): void {
    // TODO(mat21) eventually be able to just do newRoleForm.patchValue(roleToEdit)
    const idCtrl = this.newRoleForm.get('id');
    const nameToEdit = this.roleToEdit ? this.roleToEdit.roleName : undefined;
    const groupFrmCtrl = this.newRoleForm.get('existingGroup');

    if (
      idCtrl &&
      nameToEdit &&
      groupFrmCtrl &&
      this.kioskCtrl &&
      this.servicesCtrl &&
      this.adminOnlyCtrl &&
      this.descriptions &&
      this.relationships &&
      this.authCtrl
    ) {
      idCtrl.patchValue(this.roleToEdit.id);
      this.newRoleForm.patchValue({ roleName: nameToEdit });
      groupFrmCtrl.patchValue(this.containingRoleGroup);
      if (this.servicesAndKiosksControl) {
        this.servicesAndKiosksControl.patchValue({
          serviceList: this.roleToEdit.servicesAndKiosks.serviceList,
          serviceDelegation: this.roleToEdit.servicesAndKiosks.serviceDelegation,
          kioskList: this.roleToEdit.servicesAndKiosks.kioskList,
          kioskDelegation: this.roleToEdit.servicesAndKiosks.kioskDelegation,
        });
      }
      this.authCtrl.patchValue(this.roleToEdit);

      this.adminOnlyCtrl.patchValue(this.roleToEdit.roleControl);

      this.availableToStudent?.patchValue(this.roleToEdit.availableToStudent);

      this.availableToAdviser?.patchValue(this.roleToEdit.availableToAdviser);

      this.availableInElevate?.patchValue(this.roleToEdit.availableInElevate);

      this.descriptions.patchValue(this.roleToEdit.descriptions);

      this.relationships.patchValue(this.roleToEdit.relationships);
    }
  }

  // Used force mat-select to compare by value instead of by reference
  compareGroups(object1: ClientRoleGroup, object2: ClientRoleGroup): boolean {
    return equals(object1, object2);
  }

  groupChanged(event: MatRadioChange): void {
    const formCtrl = this.newRoleForm.get('existingGroup');
    if (formCtrl) {
      formCtrl.setErrors(null);
      if (event.value === 'existinggroup') {
        formCtrl.setValidators([Validators.required]);
        this.groupSelectionSubj.next(true);
      } else {
        formCtrl.setValidators([]);
        this.groupSelectionSubj.next(false);
      }
    }
    this.newRoleForm.updateValueAndValidity();
    this.groupSelection = event.value;
  }

  resetForm(): void {
    // TODO(mat21) eventually be able to just this.newRoleForm.patchValue({});
    this.groupSelectionSubj.next(true);
    this.newGroupRadioBtn.checked = true;
    if (this.servicesAndKiosksControl) {
      this.servicesAndKiosksControl.patchValue({
        serviceList: [],
        kioskList: [],
        serviceDelegation: false,
        kioskDelegation: false,
      });
    }
    if (this.relationships) {
      // TODO(mat21) make sure this does what it should
      this.relationships.reset();
    }
    if (this.authComp) {
      this.authComp.resetAuthList(false);
    }
    if (this.availableToStudent) {
      this.availableToStudent.patchValue(false);
    }
    if (this.availableToAdviser) {
      this.availableToAdviser.patchValue(false);
    }
    if (this.availableInElevate) {
      this.availableInElevate.patchValue(false);
    }
    if (this.descriptions) {
      this.descriptions.patchValue([]);
    }
  }

  isKioskSelected(): boolean {
    return this.servicesAndKiosksControl
      ? this.servicesAndKiosksControl.value.kioskList
        ? this.servicesAndKiosksControl.value.kioskList.length > 0
        : false
      : false;
  }

  isServiceSelected(): boolean {
    return this.servicesAndKiosksControl
      ? this.servicesAndKiosksControl.value.serviceList
        ? this.servicesAndKiosksControl.value.serviceList.length > 0
        : false
      : false;
  }

  getGroupSelection(): string {
    return this.groupSelection;
  }

  openOkayDialog(): void {
    this.okayDialogService.okay(
      'Admin Only Roles',
      'Roles selected with this option will not be requestable by a standard SRRS user. These roles can only be assigned to a user by an admin on the Starfish Users page.'
    );
  }
}
