<span [formGroup]="formGroup"
  class="sf-delegation-form mat-typography">

  <mat-form-field appearance="outline"
    class="full-width">
    <mat-label>Delegation Group Name</mat-label>
    <input formControlName="name"
      matInput
      placeholder="Delegation Group Name">
  </mat-form-field>

  <h2>Delegate Role Selection</h2>
  <sf-role-config-list formControlName="roleConfigs"
    (deleteRole)="deleteRole.emit($event)"
    [roles]="roles"
    class="pad-left pad-right pad-bottom">
  </sf-role-config-list>

  <h2>Calendar Management Selection</h2>
  <mat-radio-group formControlName="calendarManagement"
    color="primary"
    class="pad-left pad-right pad-bottom block">
    <mat-radio-button class="pad-right"
      data-cy="reg-and-student-rdo-btn"
      value="BOTH"><span class="normal-font">Regular & Student</span></mat-radio-button>
    <mat-radio-button class="pad-right"
      data-cy="reg-rdo-btn"
      value="REGULAR"><span class="normal-font">Regular</span></mat-radio-button>
    <mat-radio-button class="pad-right"
      data-cy="student-rdo-btn"
      value="STUDENT"><span class="normal-font">Student</span></mat-radio-button>
    <mat-radio-button class="pad-right"
      data-cy="no-cal-rdo-btn"
      value="NONE"><span class="normal-font">No Calendar Management</span></mat-radio-button>
  </mat-radio-group>

  <div class="st-dal-mgr-kiosks"
    *ngIf="formGroup.get('calendarManagement')?.value === 'STUDENT' || formGroup.get('calendarManagement')?.value === 'BOTH'">

    <h2>Student Calendar Manager Kiosks</h2>

    <sf-org-select-input #kioskSelector
      class="block pad-left"
      data-cy="kiosk-search"
      formControlName="calendarKiosks"
      [possibleItems]="kiosksAvailable | sortOrgs"
      [config]="{placeholder: 'Search for kiosks', showSearchIcon: true, removable: true, keepPanelOpen: true}">
    </sf-org-select-input>

  </div>

  <h2>Delegation Admins Selection</h2>
  <sf-typeahead-select class="block pad-left margin-bottom"
    [config]="delegationAdminConfig"
    formControlName="admins">
  </sf-typeahead-select>

</span>
