<div class="sf-user-table-comp"
  fxLayout="column">
  <h3 class="mat-h3">Manage Role Assignments</h3>
  <div class="assignment-action-btn-row">
    <button mat-flat-button
      class="user-add-btn"
      data-cy="add-assignment"
      (click)="addAssignment()"
      aria-label="Opens the menu to add a new role assignment to this delegation"
      color="primary">Add</button>
    <button mat-flat-button
      class="view-report-btn"
      data-cy="view-assignment-report"
      (click)="viewAssignmentReport()"
      [disabled]='dataSource?.data?.length < 1'
      aria-label="Shows a report of assignees and services"
      color="primary">View Report</button>
    <button mat-stroked-button
      (click)="okayDialogService.okay('Manage Role Assignments', 'Add one role at a time for selected users. If appropriate for your area, assign service and kiosk membership here. Toggle calendar management for each individual on and off. ')"
      data-cy="view-role-info-dialog"
      class="view-report-btn"
      color="primary">More Info</button>
    <mat-progress-bar *ngIf="(facade.downloadCSV$ | async) || (facade.loadingAssignments$ | async)"
      class='file-download-bar'
      data-cy="assignment-progress-bar"
      mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="table-container">
    <table class="display-flex"
      mat-table
      class="mat-elevation-z0 starfish-data-table"
      [dataSource]="dataSource">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell
          *matHeaderCellDef> User Name </th>
        <td mat-cell
          [attr.data-cy]="'td-' +element.user.displayName + '-view'"
          *matCellDef="let element"> {{element.user.displayName !== element.user.id ? element.user.displayName : (element.user.id | searchServiceGetNamePipe | async)}} </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="userid">
        <th mat-header-cell
          class="col-padding-class"
          *matHeaderCellDef> User ID </th>
        <td mat-cell
          class="col-padding-class"
          [attr.data-cy]="'td-' +element.user.id + '-view'"
          *matCellDef="let element"> {{element.user.id}} </td>
      </ng-container>

      <!-- Role Column -->
      <ng-container matColumnDef="rolename">
        <th mat-header-cell
          *matHeaderCellDef> Role Name </th>
        <td mat-cell
          *matCellDef="let element">
          <span class="add-cursor"
            fxLayoutAlign="start center">{{element.role.displayName}}
            <mat-icon class="info-course-icon"
              *ngIf="element.role.roleSelectionType === 'COURSES'"
              svgIcon="information-outline"
              [matTooltip]="element.options?.courseConfig | targetTooltip"
              matTooltipClass="target-tooltip"></mat-icon>
          </span>
        </td>
      </ng-container>

      <!-- No one can tell me what this means Column -->
      <ng-container matColumnDef="target">
        <th mat-header-cell
          *matHeaderCellDef> Target </th>
        <td mat-cell
          *matCellDef="let element"> Unused </td>
      </ng-container>

      <!-- Term Transition Checkbox Column -->
      <ng-container matColumnDef="transition">
        <th mat-header-cell
          class="col-padding-class"
          *matHeaderCellDef><span data-cy="transition-title"
            class="center-align-flex">Transition {{facade.transitionSemester$ | async}}
            <mat-icon svgIcon="help-circle-outline"
              class="transition-help-icon"
              [matTooltip]="checkboxDetails"
              matTooltipClass="target-tooltip"></mat-icon>
          </span> </th>
        <td mat-cell
          *matCellDef="let element; let i = index"><span class="center-align-flex"
            *ngIf="element.role.roleSelectionType === 'COURSES'">
            <mat-checkbox [disabled]="element.transitionState === 'NOT_TRANSITIONABLE' || element.transitionState === 'TRANSITIONED'"
              [checked]="element.transitionState === 'TRANSITIONED'"
              [attr.data-cy]="i + 'check-box-transition'"
              (change)="transitionClicked(i, $event)"
              color="primary"></mat-checkbox>
          </span></td>
      </ng-container>

      <!-- calendar mgr Column -->
      <ng-container matColumnDef="calendar">
        <th mat-header-cell
          *matHeaderCellDef> <span class="center-align-flex">Calendar Management</span> </th>
        <td mat-cell
          *matCellDef="let element; let i = index"
          [attr.data-cy]="element.user.id+'-cal-user'">
          <span class="center-align-flex">
            <mat-slide-toggle [(ngModel)]="element.calendarManagementEnabled"
              (change)="calMgrExclusionClicked(i, $event)"
              color="primary"
              aria-label="enable calendar management for this user configuration"></mat-slide-toggle>
          </span>
        </td>
      </ng-container>

      <!-- Add Role to a User Column -->
      <ng-container matColumnDef="roleconfig">
        <th mat-header-cell
          class="col-padding-class"
          *matHeaderCellDef> <span class="center-align-flex">Role Configuration</span> </th>
        <td mat-cell
          *matCellDef="let element; let i = index"
          [attr.data-cy]="element.user.id+'-edit-to-user'">
          <span class="center-align-flex">
            <button mat-flat-button
              matTooltip="Edit this configuration for this role"
              mat-button
              [disabled]="(transitionSelectedMap.get(i) | async)"
              (click)="editAssignment(i)"
              color="primary">
              Edit
            </button></span>
        </td>
      </ng-container>

      <!-- remove Column -->
      <ng-container matColumnDef="delete">
        <th mat-header-cell
          *matHeaderCellDef> <span class="center-align-flex">Remove This Configuration</span> </th>
        <td mat-cell
          *matCellDef="let element; let i = index"
          [attr.data-cy]="element.user.id+'-remove-user'">
          <span class="center-align-flex">
            <button mat-stroked-button
              matTooltip="Delete this configuration for this delegation"
              [disabled]="facade.deletingAssignment$ | async"
              mat-button
              (click)="deleteAssignment(i, element)"
              [disabled]="(transitionSelectedMap.get(i) | async)"
              color="warn">
              Delete
            </button> </span>
        </td>
      </ng-container>

      <tr mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row
        *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
