<form id="add-user-role-comp"
  [formGroup]="connectedUserFormGroup"
  fxLayout="column">
  <h2 class="mat-h2">Add Student Connected to User</h2>
  <sf-typeahead-select data-cy="one-to-one-input"
    [config]="delegationAdminConfig"
    formControlName="users">
  </sf-typeahead-select>
  <div class="user-entry-divider mat-typography">or</div>
  <h2 class="mat-h2"
    data-cy="bulk-user-heading">Add Multiple Access IDs</h2>
  <div class="mat-typography">Enter or copy/paste one or multiple Access IDs (e.g., xyz123) here.
    Note: Each Access ID must be entered on a new line.</div>
  <sf-multi-student formControlName="bulkusers"></sf-multi-student>
</form>
