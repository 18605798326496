<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
  <mat-form-field class="textarea-full-width"
    fxFlex>
    <textarea [(ngModel)]="commentsBody"
      rows="5"
      matInput
      placeholder="Enter comments here"></textarea>
  </mat-form-field>
</div>
<div fxLayoutAlign="end center"
  mat-dialog-actions>
  <button type="button"
    disabled
    [attr.disabled]="commentsBody.length < 1 ? true : undefined"
    mat-button
    color=primary
    (click)="dialogRef.close(commentsBody)">OK</button>
  <button type="button"
    mat-button
    color=warn
    (click)="dialogRef.close('')">Cancel</button>
</div>
