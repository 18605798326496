<h2 class="mat-h2">{{data.users?.length}} Students could not be added to this assignment</h2>

<div class="missing-user-dialog-content"
  mat-dialog-content
  fxLayout="column">

  <sf-account-search #searchInput
    placeHolderText="Search by User ID"
    (onSearch)='filterSubject$.next($event)'></sf-account-search>

  <p class="warning-text"
    data-cy="no-search-results"
    *ngIf="(filteredChips$ | async)?.length === 0">There are no records that match that access id. Please clear the search bar to see all student IDs or search again.</p>

  <mat-chip-listbox class="chip-list-group">
    <mat-chip-option *ngFor="let thing of (filteredChips$ | async | sortDisplayName)"
      class="chip-padding chip-color"
      [removable]="'false'"
      [attr.data-cy]="'chip-' + thing.id">{{thing.id}}
    </mat-chip-option>
  </mat-chip-listbox>

</div>

<div mat-dialog-actions
  class="end-align-flex missing-user-dialog-actions">
  <sf-generate-csv class="action-button"
    buttonLabel="Download and Remove"
    [mat-dialog-close]="true"
    [dataFile]="filteredChips$ | async | sortDisplayName | toIdList"></sf-generate-csv>
  <button mat-flat-button
    class="action-button"
    color="primary"
    [mat-dialog-close]="false"
    cdkFocusInitial>OK</button>
</div>
