import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { OrientationTrainingService } from '../services/orientation/training.service';

@Injectable()
export class StarfishGuard  {
  constructor(public router: Router, private trainingService: OrientationTrainingService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.trainingService.getEula$.pipe(
      take(1),
      map((hasTraining) => {
        if (hasTraining === null) {
          return false;
        }
        return hasTraining;
      }),
      tap((hasTraining) => {
        if (!hasTraining) {
          // TODO if it's possible to refresh the training status after training is completed,
          // we could redirect them back to the state.url to which they were originally going.
          this.router.navigate(['training']);
        }
      })
    );
  }
}
