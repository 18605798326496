<span class="sf-simple-service-selector">
  <mat-form-field appearance="outline"
    class="form-field">
    <mat-label>{{serviceType}} Selection</mat-label>
    <mat-select multiple
      #selectElement
      (openedChange)="notificationEmitter.emit()"
      [attr.data-cy]="'select-' + serviceType"
      [compareWith]="compareOrgModels"
      [formControl]="serviceSelector">
      <mat-option (onSelectionChange)="selectionChange($event)"
        data-cy="no-service-needed-option"
        value="ALL">-- No {{serviceType}}s Needed --</mat-option>
      <mat-option (onSelectionChange)="selectionChange($event)"
        data-cy="admin-area-choose-option"
        value="ADMIN_AREA_CHOOSE">-- Let Admin Area Choose --</mat-option>
      <mat-optgroup [label]="'Available ' + serviceType + 's'">
        <mat-option *ngFor="let item of (possibleItems | sortOrgModels)"
          [attr.data-cy]="item.code + '-option'"
          (onSelectionChange)="selectionChange($event)"
          [value]="item">{{item.name}} [{{item.code}}]</mat-option>
      </mat-optgroup>
    </mat-select>
    <mat-error data-cy="service-selection-required">You must make a selection</mat-error>
  </mat-form-field>
</span>
