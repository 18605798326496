<section class="spaced-cards"
  fxLayout="column">

  <h1>Request #{{processId}}</h1>

  <sf-workflow-details [processDetails]=requestItemDetailsObs
    [fetchingDetails]=isFetchingObs
    [hasFerpa]=hasFerpa
    [taskDetails]='taskDetails | async'
    isRequestView="true"
    (addCommentEmitter)="newCommentDialog()">
  </sf-workflow-details>

  <sf-workflow-history [isFetchingWorkflowHistory]=isFetchingObs
    [processDetails]=requestItemDetailsObs>
  </sf-workflow-history>

</section>
