<mat-form-field appearance="outline">
  <mat-label>Edit Role Name</mat-label>
  <input matInput
    [formControl]=roleName
    placeholder="Edit Role Name"
    data-cy='edit-role-name'>
  <!-- <mat-error *ngIf="newRoleForm.get('name').errors && newRoleForm.get('name').errors.required">You must supply a name</mat-error>
    <mat-error *ngIf="newRoleForm.get('name').errors && newRoleForm.get('name').errors.exists">This group name already exists for the selected Group Type. Please enter a different name</mat-error> -->
</mat-form-field>

<!-- formControlName="name" -->
